export const Categories = [
	{
		label: "Obst",
		name: "obst",
		id: 1,
		imageSrc: "/static-images/fruits.jpg",
	},
	{
		label: "Gemüse",
		name: "gemuese",
		id: 2,
		imageSrc: "/static-images/veggies.jpg",
	},
	{
		label: "Trocken",
		name: "trocken",
		id: 7,
		imageSrc: "/static-images/dry.jpg",
	},
	{
		label: "Flüssig",
		name: "fluessig",
		id: 6,
		imageSrc: "/static-images/liquid.jpg",
	},
	{
		label: "Kühlware",
		name: "kuehlware",
		id: 4,
		imageSrc: "/static-images/fridge.jpg",
	},
	{
		label: "Haltbar",
		name: "haltbar",
		id: 8,
		imageSrc: "/static-images/canned.jpg",
	},
	{
		label: "Würze",
		name: "gewuerz",
		id: 10,
		imageSrc: "/static-images/spice.jpg",
	},
	{
		label: "Kosmetik",
		name: "kosmetik",
		id: 9,
		imageSrc: "/static-images/cosmetics.jpg",
	},
	{
		label: "Tiefkühl",
		name: "tiefkuehl",
		id: 3,
		imageSrc: "/static-images/freezer.jpg",
	},
	{
		label: "Snacks",
		name: "snacks",
		id: 5,
		imageSrc: "/static-images/snacks.jpg",
	},
];

const CategoryID = {
	FRUIT: 1,
	VEGETABLES: 2,
	FROST: 3,
	FRIDGE: 4,
	SNACKS: 5,
	LIQUID: 6,
	DRY: 7,
	CANNED: 8,
	COSMETIC: 9,
	SPICE: 10,
};

export const Items = [
	{
		id: 1,
		label: "Schnapfel",
		category: CategoryID.FRUIT,
	},
	{
		id: 2,
		label: "Salat",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 3,
		label: "Tomaten",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 4,
		label: "Traubis",
		category: CategoryID.FRUIT,
	},
	{
		id: 5,
		label: "Weißwein",
		category: CategoryID.LIQUID,
	},
	{
		id: 6,
		label: "Rotwein",
		category: CategoryID.LIQUID,
	},
	{
		id: 7,
		label: "PToms",
		category: CategoryID.CANNED,
	},
	{
		id: 8,
		label: "Hefeflocken",
		category: CategoryID.DRY,
	},
	{
		id: 9,
		label: "Klopapier",
		category: CategoryID.COSMETIC,
	},
	{
		id: 10,
		label: "Taschentücher",
		category: CategoryID.COSMETIC,
	},
	{
		id: 11,
		label: "Ohrenstäbchen",
		category: CategoryID.COSMETIC,
	},
	{
		id: 12,
		label: "Zahnpasta",
		category: CategoryID.COSMETIC,
	},
	{
		id: 13,
		label: "Mehl",
		category: CategoryID.DRY,
	},
	{
		id: 14,
		label: "Jalas",
		category: CategoryID.CANNED,
	},
	{
		id: 15,
		label: "Tofu (natur)",
		category: CategoryID.FRIDGE,
	},
	{
		id: 16,
		label: "Tofu (geräuchert)",
		category: CategoryID.FRIDGE,
	},
	{
		id: 17,
		label: "Süßkartoffel",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 18,
		label: "Brot",
		category: CategoryID.DRY,
	},
	{
		id: 19,
		label: "TMark",
		category: CategoryID.CANNED,
	},
	{
		id: 20,
		label: "Zwiebeln",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 21,
		label: "Lauch",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 22,
		label: "Möhris",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 23,
		label: "Champis",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 24,
		label: "Agavendicksaft",
		category: CategoryID.LIQUID,
	},
	{
		id: 25,
		label: "Schoki",
		category: CategoryID.SNACKS,
	},
	{
		id: 26,
		label: "Cashews",
		category: CategoryID.DRY,
	},
	{
		id: 27,
		label: "SB Kerne",
		category: CategoryID.DRY,
	},
	{
		id: 28,
		label: "Kürbiskerne",
		category: CategoryID.DRY,
	},
	{
		id: 29,
		label: "Bratöl",
		category: CategoryID.LIQUID,
	},
	{
		id: 30,
		label: "Paras",
		category: CategoryID.DRY,
	},
	{
		id: 31,
		label: "Senf",
		category: CategoryID.SPICE,
	},
	{
		id: 32,
		label: "Ketchup",
		category: CategoryID.SPICE,
	},
	{
		id: 33,
		label: "Vurst",
		category: CategoryID.FRIDGE,
	},
	{
		id: 34,
		label: "Hummus",
		category: CategoryID.FRIDGE,
	},
	{
		id: 35,
		label: "Cooliven",
		category: CategoryID.FRIDGE,
	},
	{
		id: 36,
		label: "Guaca",
		category: CategoryID.FRIDGE,
	},
	{
		id: 37,
		label: "Kartoffeln",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 38,
		label: "Knoblauch",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 39,
		label: "Petersilie",
		category: CategoryID.SPICE,
	},
	{
		id: 40,
		label: "Essig Essenz",
		category: CategoryID.LIQUID,
	},
	{
		id: 41,
		label: "Kräuteressig",
		category: CategoryID.LIQUID,
	},
	{
		id: 42,
		label: "Apfelsaft",
		category: CategoryID.LIQUID,
	},
	{
		id: 43,
		label: "Cola",
		category: CategoryID.LIQUID,
	},
	{
		id: 44,
		label: "Pfeffer",
		category: CategoryID.SPICE,
	},
	{
		id: 45,
		label: "Salz",
		category: CategoryID.SPICE,
	},
	{
		id: 46,
		label: "Oregano",
		category: CategoryID.SPICE,
	},
	{
		id: 47,
		label: "Zimt",
		category: CategoryID.SPICE,
	},
	{
		id: 48,
		label: "Sojachunks",
		category: CategoryID.DRY,
	},
	{
		id: 49,
		label: "Schwarze Bohnen",
		category: CategoryID.CANNED,
	},
	{
		id: 50,
		label: "Sauris",
		category: CategoryID.CANNED,
	},
	{
		id: 51,
		label: "Pesto",
		category: CategoryID.CANNED,
	},
	{
		id: 52,
		label: "Nudeln",
		category: CategoryID.DRY,
	},
	{
		id: 53,
		label: "Reis",
		category: CategoryID.DRY,
	},
	{
		id: 54,
		label: "Margarine",
		category: CategoryID.FRIDGE,
	},
	{
		id: 55,
		label: "Birnen",
		category: CategoryID.FRUIT,
	},
	{
		id: 56,
		label: "Heidelbeeren",
		category: CategoryID.FRUIT,
	},
	{
		id: 57,
		label: "Kirschen",
		category: CategoryID.FRUIT,
	},
	{
		id: 58,
		label: "Orangen",
		category: CategoryID.FRUIT,
	},
	{
		id: 59,
		label: "Gurke",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 60,
		label: "Paprika",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 61,
		label: "Deo",
		category: CategoryID.COSMETIC,
	},
	{
		id: 62,
		label: "Duschseife",
		category: CategoryID.COSMETIC,
	},
	{
		id: 63,
		label: "Zucker",
		category: CategoryID.DRY,
	},
	{
		id: 64,
		label: "Shampoo",
		category: CategoryID.COSMETIC,
	},
	{
		id: 65,
		label: "Z-Saft",
		category: CategoryID.LIQUID,
	},
	{
		id: 66,
		label: "Waschmittel",
		category: CategoryID.COSMETIC,
	},
	{
		id: 67,
		label: "Spülsalz",
		category: CategoryID.COSMETIC,
	},
	{
		id: 68,
		label: "Spüli",
		category: CategoryID.COSMETIC,
	},
	{
		id: 69,
		label: "Küchenrolle",
		category: CategoryID.COSMETIC,
	},
	{
		id: 70,
		label: "Zucchini",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 71,
		label: "Aubergine",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 72,
		label: "Frühlingszwiebeln",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 73,
		label: "Erdnussbutter",
		category: CategoryID.CANNED,
	},
	{
		id: 74,
		label: "Letscho",
		category: CategoryID.CANNED,
	},
	{
		id: 75,
		label: "Chips",
		category: CategoryID.SNACKS,
	},
	{
		id: 76,
		label: "Spinat (TK)",
		category: CategoryID.FROST,
	},
	{
		id: 77,
		label: "Spinat",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 78,
		label: "Röstzwiebeln",
		category: CategoryID.DRY,
	},
	{
		id: 79,
		label: "Mayo",
		category: CategoryID.SPICE,
	},
	{
		id: 81,
		label: "Käse",
		category: CategoryID.FRIDGE,
	},
	{
		id: 82,
		label: "Kloßteig",
		category: CategoryID.FRIDGE,
	},
	{
		id: 83,
		label: "Pizzateig",
		category: CategoryID.FRIDGE,
	},
	{
		id: 84,
		label: "Kidney Bohnen",
		category: CategoryID.CANNED,
	},
	{
		id: 85,
		label: "Ajvar",
		category: CategoryID.CANNED,
	},
	{
		id: 86,
		label: "Toast",
		category: CategoryID.DRY,
	},
	{
		id: 87,
		label: "Avocado",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 88,
		label: "Kapern",
		category: CategoryID.CANNED,
	},
	{
		id: 89,
		label: "Spargel",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 90,
		label: "Rosenkohl",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 91,
		label: "Blumenkohl",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 92,
		label: "Pizza",
		category: CategoryID.FROST,
	},
	{
		id: 93,
		label: "Pfannengericht",
		category: CategoryID.FROST,
	},
	{
		id: 94,
		label: "Chinakohl",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 95,
		label: "Mais",
		category: CategoryID.CANNED,
	},
	{
		id: 96,
		label: "Bananen",
		category: CategoryID.FRUIT,
	},
	{
		id: 97,
		label: "Himbeeren",
		category: CategoryID.FRUIT,
	},
	{
		id: 98,
		label: "Milch",
		category: CategoryID.LIQUID,
	},
	{
		id: 99,
		label: "Frischkäse",
		category: CategoryID.FRIDGE,
	},
	{
		id: 100,
		label: "Baked Beans",
		category: CategoryID.CANNED,
	},
	{
		id: 101,
		label: "Couscous",
		category: CategoryID.DRY,
	},
	{
		id: 102,
		label: "Linsen (rot)",
		category: CategoryID.DRY,
	},
	{
		id: 103,
		label: "Bulgur",
		category: CategoryID.DRY,
	},
	{
		id: 104,
		label: "Salsa",
		category: CategoryID.SPICE,
	},
	{
		id: 105,
		label: "Tortillas",
		category: CategoryID.DRY,
	},
	{
		id: 106,
		label: "CO2 Flasche",
		category: CategoryID.LIQUID,
	},
	{
		id: 107,
		label: "Schwämme",
		category: CategoryID.COSMETIC,
	},
	{
		id: 108,
		label: "Burger Patties",
		category: CategoryID.FROST,
	},
	{
		id: 109,
		label: "Radieschen",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 110,
		label: "Rote Beete",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 111,
		label: "Rotkohl",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 112,
		label: "Weißkohl",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 113,
		label: "Kohlrabi",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 114,
		label: "Grüne Bohnen",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 115,
		label: "Kürbis",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 116,
		label: "Brokkoli",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 117,
		label: "Rucola",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 118,
		label: "Feldsalat",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 119,
		label: "Grünkohl",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 120,
		label: "Erbsen",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 121,
		label: "Staudensellerie",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 122,
		label: "Erdbeeren",
		category: CategoryID.FRUIT,
	},
	{
		id: 123,
		label: "Kichererbsen",
		category: CategoryID.CANNED,
	},
	{
		id: 124,
		label: "Tahin",
		category: CategoryID.CANNED,
	},
	{
		id: 125,
		label: "Chili",
		category: CategoryID.SPICE,
	},
	{
		id: 126,
		label: "Thymian",
		category: CategoryID.SPICE,
	},
	{
		id: 127,
		label: "Rosmarin",
		category: CategoryID.SPICE,
	},
	{
		id: 128,
		label: "Dill",
		category: CategoryID.SPICE,
	},
	{
		id: 129,
		label: "Joghurt",
		category: CategoryID.FRIDGE,
	},
	{
		id: 130,
		label: "Spätzle",
		category: CategoryID.FRIDGE,
	},
	{
		id: 131,
		label: "Brokkoli (TK)",
		category: CategoryID.FROST,
	},
	{
		id: 132,
		label: "TK Kräuter",
		category: CategoryID.FROST,
	},
	{
		id: 133,
		label: "Eiersalat",
		category: CategoryID.FRIDGE,
	},
	{
		id: 134,
		label: "Curry",
		category: CategoryID.SPICE,
	},
	{
		id: 135,
		label: "Spültabs",
		category: CategoryID.COSMETIC,
	},
	{
		id: 136,
		label: "Süßer Senf",
		category: CategoryID.SPICE,
	},
	{
		id: 137,
		label: "Peeling",
		category: CategoryID.COSMETIC,
	},
	{
		id: 138,
		label: "Zahnseide",
		category: CategoryID.COSMETIC,
	},
	{
		id: 139,
		label: "Handseife",
		category: CategoryID.COSMETIC,
	},
	{
		id: 140,
		label: "Klarspüler",
		category: CategoryID.COSMETIC,
	},
	{
		id: 141,
		label: "Currypaste",
		category: CategoryID.SPICE,
	},
	{
		id: 142,
		label: "Rasierklingen",
		category: CategoryID.COSMETIC,
	},
	{
		id: 143,
		label: "Zahnbürste",
		category: CategoryID.COSMETIC,
	},
	{
		id: 144,
		label: "Erdnüsse",
		category: CategoryID.DRY,
	},
	{
		id: 145,
		label: "Koriander",
		category: CategoryID.SPICE,
	},
	{
		id: 146,
		label: "getr. Tomaten",
		category: CategoryID.DRY,
	},
	{
		id: 147,
		label: "Sellerie",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 148,
		label: "Tee",
		category: CategoryID.LIQUID,
	},
	{
		id: 149,
		label: "Ingwer",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 150,
		label: "Zitronen",
		category: CategoryID.FRUIT,
	},
	{
		id: 151,
		label: "Limette",
		category: CategoryID.FRUIT,
	},
	{
		id: 152,
		label: "Bonbons",
		category: CategoryID.SNACKS,
	},
	{
		id: 153,
		label: "Gummibären",
		category: CategoryID.SNACKS,
	},
	{
		id: 154,
		label: "Marmelade",
		category: CategoryID.CANNED,
	},
	{
		id: 155,
		label: "Sauerkraut",
		category: CategoryID.CANNED,
	},
	{
		id: 156,
		label: "Butter",
		category: CategoryID.FRIDGE,
	},
	{
		id: 157,
		label: "Pak Choi",
		category: CategoryID.VEGETABLES,
	},
	{
		id: 158,
		label: "Kreuzkümmel",
		category: CategoryID.SPICE,
	},
	{
		id: 159,
		label: "Leinsamen",
		category: CategoryID.DRY,
	},
	{
		id: 160,
		label: "Haferflocken",
		category: CategoryID.DRY,
	},
	{
		id: 161,
		label: "Salatöl",
		category: CategoryID.LIQUID,
	},
	{
		id: 162,
		label: "Balsamico",
		category: CategoryID.LIQUID,
	},
	{
		id: 163,
		label: "Sahne / Soja-Cuisine",
		category: CategoryID.LIQUID,
	},
	{
		id: 164,
		label: "BBQ Sauce",
		category: CategoryID.SPICE,
	},
	{
		id: 165,
		label: "Kräutersalz",
		category: CategoryID.SPICE,
	},
	{
		id: 166,
		label: "Brötchen TK",
		category: CategoryID.FROST,
	},
	{
		id: 167,
		label: "Linsen (Dose)",
		category: CategoryID.CANNED,
	},
	{
		id: 168,
		label: "Blätterteig",
		category: CategoryID.FROST,
	},
	{
		id: 170,
		label: "Maultäschle",
		category: CategoryID.FRIDGE,
	},
	{
		id: 171,
		label: "Sojasauce",
		category: CategoryID.LIQUID,
	},
	{
		id: 172,
		label: "Baba Ganoush",
		category: CategoryID.FRIDGE,
	},
	{
		id: 173,
		label: "Artischocken (Glas)",
		category: CategoryID.CANNED,
	},
	{
		id: 174,
		label: "Dunkle Soße",
		category: CategoryID.LIQUID,
	},
	{
		id: 175,
		label: "Gemüsebrühe",
		category: CategoryID.SPICE,
	},
	{
		id: 176,
		label: "Edamame",
		category: CategoryID.FRIDGE,
	},
	{
		id: 178,
		label: "Paprika",
		category: CategoryID.SPICE,
	},
	{
		id: 179,
		label: "Hefe (Würfel)",
		category: CategoryID.FRIDGE,
	},
	{
		id: 180,
		label: "Müllbeutel",
		category: CategoryID.COSMETIC,
	},
	{
		id: 181,
		label: "Batterien AA",
		category: CategoryID.COSMETIC,
	},
	{
		id: 182,
		label: "Kurkuma",
		category: CategoryID.SPICE,
	},
	{
		id: 183,
		label: "Hirse",
		category: CategoryID.DRY,
	},
	{
		id: 184,
		label: "Quinoa",
		category: CategoryID.DRY,
	},
	{
		id: 185,
		label: "Buchweizen",
		category: CategoryID.DRY,
	},
	{
		id: 186,
		label: "Refried Beans",
		category: CategoryID.CANNED,
	},
	{
		id: 187,
		label: "Minze",
		category: CategoryID.SPICE,
	},
	{
		id: 188,
		label: "Mandeln",
		category: CategoryID.DRY,
	},
	{
		id: 189,
		label: "Zwiebelpulver",
		category: CategoryID.SPICE,
	},
	{
		id: 190,
		label: "Klebeband",
		category: CategoryID.COSMETIC,
	},
	{
		id: 191,
		label: "Miso",
		category: CategoryID.SPICE,
	},
	{
		id: 192,
		label: "Briefmarken",
		category: CategoryID.COSMETIC,
	},
	{
		id: 193,
		label: "Tafelessig",
		category: CategoryID.LIQUID,
	},
	{
		id: 194,
		label: "Kala Namak",
		category: CategoryID.SPICE,
	},
	{
		id: 195,
		label: "Walnüsse",
		category: CategoryID.DRY,
	},
	{
		id: 196,
		label: "Rosinen",
		category: CategoryID.DRY,
	},
	{
		id: 197,
		label: "Pinienkerne",
		category: CategoryID.DRY,
	},
	{
		id: 198,
		label: "Mate",
		category: CategoryID.LIQUID,
	},
	{
		id: 199,
		label: "Edamame (Dose)",
		category: CategoryID.CANNED,
	},
	{
		id: 200,
		label: "Linsen (braun)",
		category: CategoryID.DRY,
	},
	{
		id: 201,
		label: "Pflaster",
		category: CategoryID.COSMETIC,
	},
	{
		id: 202,
		label: "Pfirsiche",
		category: CategoryID.FRUIT,
	},
	{
		id: 203,
		label: "Nektarinen",
		category: CategoryID.FRUIT,
	},
	{
		id: 204,
		label: "Textilerfrischer",
		category: CategoryID.COSMETIC,
	},
	{
		id: 205,
		label: "Spagetthi",
		category: CategoryID.DRY,
	},
	{
		id: 206,
		label: "Quark",
		category: CategoryID.FRIDGE,
	},
	{
		id: 207,
		label: "Glasreiniger",
		category: CategoryID.COSMETIC,
	},
	{
		id: 208,
		label: "WC-Ente",
		category: CategoryID.COSMETIC,
	},
	{
		id: 209,
		label: "Pistazien",
		category: CategoryID.DRY,
	},
	{
		id: 210,
		label: "Desinfektionstücher",
		category: CategoryID.COSMETIC,
	},
	{
		id: 211,
		label: "Zitronensäure",
		category: CategoryID.COSMETIC,
	},
	{
		id: 212,
		label: "Natron",
		category: CategoryID.COSMETIC,
	},
	{
		id: 213,
		label: "Tortilla Chips",
		category: CategoryID.SNACKS,
	},
	{
		id: 214,
		label: "Schoko Brezeln",
		category: CategoryID.SNACKS,
	},
	{
		id: 215,
		label: "Donut Flips",
		category: CategoryID.SNACKS,
	},
	{
		id: 216,
		label: "Kimchi",
		category: CategoryID.FRIDGE,
	},
	{
		id: 217,
		label: "Laxx",
		category: CategoryID.FRIDGE,
	},
	{
		id: 218,
		label: "Gnocchi",
		category: CategoryID.FRIDGE,
	},
	{
		id: 219,
		label: "Reisnudeln",
		category: CategoryID.DRY,
	},
	{
		id: 220,
		label: "Reispapier",
		category: CategoryID.DRY,
	},
	{
		id: 221,
		label: "Schupfnudeln",
		category: CategoryID.FRIDGE,
	},
	{
		id: 222,
		label: "Pinto Bohnen",
		category: CategoryID.CANNED,
	},
	{
		id: 223,
		label: "Backpulver",
		category: CategoryID.DRY,
	},
	{
		id: 224,
		label: "Kakao",
		category: CategoryID.DRY,
	},
	{
		id: 225,
		label: "Sprossenmix",
		category: CategoryID.FRIDGE,
	},
	{
		id: 226,
		label: "WC-Erfrischer",
		category: CategoryID.COSMETIC,
	},
	{
		id: 227,
		label: "Dental Sticks",
		category: CategoryID.COSMETIC,
	},
	{
		id: 228,
		label: "Müsli-Mix",
		category: CategoryID.DRY,
	},
	{
		id: 229,
		label: "Rissotto-Reis",
		category: CategoryID.DRY,
	},
	{
		id: 230,
		label: "Milchreis",
		category: CategoryID.DRY,
	},
	{
		id: 231,
		label: "Frikadellen",
		category: CategoryID.FRIDGE,
	},
	{
		id: 232,
		label: "Remoulade",
		category: CategoryID.SPICE,
	},
	{
		id: 233,
		label: "Snack-Bällchen",
		category: CategoryID.FRIDGE,
	},
	{
		id: 234,
		label: "Sriracha",
		category: CategoryID.SPICE,
	},
	{
		id: 235,
		label: "Wasser",
		category: CategoryID.LIQUID,
	},
	{
		id: 236,
		label: "Batterien AAA",
		category: CategoryID.COSMETIC,
	},
	{
		id: 237,
		label: "Apfelessig",
		category: CategoryID.LIQUID,
	},
	{
		id: 238,
		label: "Bier",
		category: CategoryID.LIQUID,
	},
	{
		id: 239,
		label: "Hackvleisch",
		category: CategoryID.FRIDGE,
	},
	{
		id: 240,
		label: "Tabasco",
		category: CategoryID.SPICE,
	},
	{
		id: 241,
		label: "Lappen",
		category: CategoryID.COSMETIC,
	},
	{
		id: 242,
		label: "Knäckebrot",
		category: CategoryID.DRY,
	},
	{
		id: 243,
		label: "Backpapier",
		category: CategoryID.COSMETIC,
	},
	{
		id: 244,
		label: "Briefumschläge",
		category: CategoryID.COSMETIC,
	},
	{
		id: 245,
		label: "Weißweinessig",
		category: CategoryID.LIQUID,
	},
	{
		id: 246,
		label: "Senfkörner",
		category: CategoryID.SPICE,
	},
	{
		id: 247,
		label: "Mundspülung",
		category: CategoryID.COSMETIC,
	},
	{
		id: 248,
		label: "Eiscreme",
		category: CategoryID.FROST,
	},
	{
		id: 249,
		label: "Muskat",
		category: CategoryID.SPICE,
	},
]
	.sort((a, b) => {
		const labelA = a.label.toLowerCase();
		const labelB = b.label.toLowerCase();
		if (labelA < labelB) return -1;
		if (labelA > labelB) return 1;
		return 0;
	})
	.map((entry) => {
		return {
			...entry,
			isOnList: false,
			isCrossedOff: false,
			isInSeason: false,
		};
	});

export const seasonProductIds = {
	APFEL: 1,
	SALAT: 2,
	TOMATEN: 3,
	WEINTRAUBEN: 4,
	SUESSKARTOFFEL: 17,
	ZWIEBEL: 20,
	LAUCH: 21,
	MOEHREN: 22,
	CHAMPIGNONS: 23,
	KARTOFFELN: 37,
	KNOBLAUCH: 38,
	BIRNEN: 55,
	HEIDELBEEREN: 56,
	KIRSCHEN: 57,
	ORANGEN: 58,
	GURKE: 59,
	PAPRIKA: 60,
	ZUCCHINI: 70,
	AUBERGINE: 71,
	FRUEHL_ZWIEBELN: 72,
	SPINAT: 77,
	AVOCADO: 87,
	SPARGEL: 89,
	ROSENKOHL: 90,
	BLUMENKOHL: 91,
	CHINAKOHL: 94,
	MAIS: 95,
	BANANEN: 96,
	HIMBEEREN: 97,
	RADIESCHEN: 109,
	ROTE_BEETE: 110,
	ROTKOHL: 111,
	WEISSKOHL: 112,
	KOHLRABI: 113,
	GRUENE_BOHNEN: 114,
	KUERBIS: 115,
	BROKKOLI: 116,
	RUCOLA: 117,
	FELDSALAT: 118,
	GRUENKOHL: 119,
	ERBSEN: 120,
	STAUDENSELLERIE: 121,
	ERDBEERE: 122,
};
